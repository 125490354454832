<template>
  <div>
    <div class="card">
      <el-page-header @back="goBack" :content="content"></el-page-header>
    </div>
    <div class="box">
      <el-button type="primary" plain @click="handlePrint" :loading="printLoading">打印</el-button>
      <el-button type='primary' plain  icon='el-icon-upload2' @click="handleExport" :loading="exportLoading">导出</el-button>
    </div>
    <div class="info">
      <div class="item">
        <div class="item-left">出库单号</div>
        <div class="item-right">{{form.material_outflow_number || '--'}}</div>
      </div>
      <div class="item">
        <div class="item-left">供应商</div>
        <div class="item-right">{{form.supplier_name || '--'}}</div>
      </div>
      <div class="item">
        <div class="item-left">备注</div>
        <div class="item-right">{{form.remark || '--'}}</div>
      </div>
    </div>
    <div class="table">
          <el-table
            :data="tableData"
            style="width: 100%"
            stripe
            v-loading="loading"
            :row-class-name="picRowClassName"
          >
            <template v-for="item in rowList">
              <el-table-column
                :key="item.field_alias"
                :prop="item.field_alias"
                :label="item.field_text"
                align="center"
                :width="item.t_width"
              >
                <!-- <template slot="header" slot-scope="scope">
                  <span :key="item.field_alias">
                    <span v-if="item.require" style="color: #f56c6c">*</span>
                    {{ item.field_text }}
                  </span>
                </template> -->
                <template slot-scope="scope">
                  {{(scope.row[item.field_alias] || scope.row[item.field_alias] === 0) ? scope.row[item.field_alias] : '--'}}
                </template>
              </el-table-column>
            </template>
          </el-table>
    </div>
    <div v-if="type === 'examine'" class="btns">
      <el-button type="primary" @click="handleAudit(30)"
        >审核通过</el-button
      >
      <el-button @click="handleAudit(20)">审核不通过</el-button>
    </div>
    <LocalPrint
      :show="false"
      :printRows="printRows"
      :printData="printData"
      :title="title"
      :dialogVisible="localPrintVisible"
      @onClose="localPrintVisible = false"
    />
  </div>
</template>

<script>
import {
  getDetailReq,
  handleAuditReq,
  exportDetailReq,
} from "@/api/goods/raw/out/index";
import LocalPrint from "@/components/localPrint/Index.vue";

export default {
  data() {
    return {
      printLoading: false,
      detailRows: [
        {
          field: "index",
          field_alias: "index",
          field_text: "序号",
          is_goods: 0,
          is_sum: 0,
          is_use: 1,
          width: 35,
        },
        {
          field: "attr_value",
          field_alias: "attr_value",
          field_text: "材质成色",
          is_goods: 0,
          is_sum: 0,
          is_use: 1,
          width: 35,
        },
        {
          field: "gold_weight",
          field_alias: "gold_weight",
          field_text: "重量",
          is_goods: 0,
          is_sum: 0,
          is_use: 1,
          width: 35,
        },
        {
          field: "unit_price",
          field_alias: "unit_price",
          field_text: "单价",
          is_goods: 0,
          is_sum: 0,
          is_use: 1,
          width: 35,
        },
        {
          field: "amount_money",
          field_alias: "amount_money",
          field_text: "金额",
          is_goods: 0,
          is_sum: 0,
          is_use: 1,
          width: 35,
        },
        {
          field: "remark",
          field_alias: "remark",
          field_text: "备注",
          is_goods: 0,
          is_sum: 0,
          is_use: 1,
          width: 35,
        },
      ],
      title: "",
      localPrintVisible: false,
      printData: {},
      printRows: [], // 打印的行
      exportLoading: false,
      loading: false,
      content: '',
      form: {},
      tableData: [],
      rowList: [
        { field_text: "序号", field_alias: "index" },
        { field_text: "材质成色", field_alias: "attr_value", require: true },
        { field_text: "重量", field_alias: "gold_weight", require: true, min: 0 },
        { field_text: "单价", field_alias: "unit_price", require: true, min: 0 },
        { field_text: "金额", field_alias: "amount_money", require: true },
        { field_text: "备注", field_alias: "remark" },
      ],
    };
  },
  props: {
    row: Object,
    type: String,
  },
  components: {
    LocalPrint,
  },
  created() {
    if (this.type === "detail") {
      this.content = "金料出库详情";
    }
    if (this.type === "examine") {
      this.content = "金料出库审核";
    }
    this.getDetail();
  },
  methods: {
    // 打印
    handlePrint() {
      this.printLoading = true;
      getDetailReq({
          material_outflow_id: this.row.material_outflow_id,
        })
          .then((res) => {
            this.printLoading = false;
            if (res.code === 1) {
              this.title = '金料出库单详情';
              this.printData.top = [];
              const arr1 = [
                { txt: "出库单号", value: res.data.material_outflow_number, width: "24%" },
                { txt: "供应商", value: res.data.supplier_name, width: "24%" },
                { txt: "备注", value: res.data.remark, width: "24%" },
              ];
              this.printData.top.push(arr1);
              this.printRows = this.detailRows;
              res.data.goods.forEach((item, index) => {
                item.index = index + 1;
              });
              this.printData.list = res.data.goods;
              this.localPrintVisible = true;
            }
          })
          .catch(() => {
            this.printLoading = false;
          });
    },
    // 导出
    handleExport() {
      this.exportLoading = true;
      exportDetailReq({
        material_outflow_id: this.row.material_outflow_id,
      })
      .then((res) => {
        this.exportLoading = false;
        let blob = new Blob([res], { type: "application/vnd.ms-excel" });
        const a = document.createElement("a");
        a.setAttribute("download", `金料出库单详情.xlsx`);
        a.href = URL.createObjectURL(blob);
        a.click();
      })
      .catch(() => {
        this.exportLoading = false;
      });
    },
    // 修改状态
    handleAudit(status) {
      let content = '';
      if (status === 30) {
        content = '确定要审核通过吗？'
      }
      if (status === 20) {
        content = '确定要审核不通过吗？'
      }
      this.$confirm(content, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          handleAuditReq({
            material_outflow_id: this.row.material_outflow_id,
            status,
          })
            .then((res) => {
              if (res.code === 1) {
                this.$message.success("操作成功");
                this.$emit('onBack', 'getList');
              }
            })
            .catch(() => {
              this.currRow.select = "";
            });
        })
        .catch(() => {});
    },
    goBack() {
      this.$emit('onBack');
    },
    picRowClassName({ row, rowIndex }) {
      // 给每一行添加索引
      row.index = rowIndex + 1;
    },
    getDetail() {
      getDetailReq({
        material_outflow_id: this.row.material_outflow_id,
      })
        .then((res) => {
          if (res.code === 1) {
            this.form = {
              material_outflow_number: res.data.material_outflow_number,
              supplier_id: res.data.supplier_id,
              remark: res.data.remark,
              supplier_name: res.data.supplier_name,
            };
            this.tableData = res.data.goods;
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  background: white;
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
}
.btns {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
.info {
  padding: 20px 50px 10px 50px;
  display: flex;
  background: white;
}
.item {
    display: flex;
    margin-bottom: 20px;
    width: 25%;
    &-left {
      width: 100px;
      color: #909399;
      font-size: 16px;
      position: relative;
      &::before {
        content: "";
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background: #2791ff;
        position: absolute;
        top: 10px;
        left: -15px;
      }
    }
    &-right {
      color: #565656;
      font-size: 16px;
    }
  }
</style>
