<template>
  <div>
    <div class="card">
      <el-page-header @back="goBack" :content="content"> </el-page-header>
    </div>
    <div class="box">
      <el-form :model="form" inline>
        <el-form-item label="出库单号">
          <el-input
            style="width: 250px;"
            v-model="form.material_outflow_number"
            :disabled="type === 'detail' || type === 'examine' ? true : false"
          ></el-input>
        </el-form-item>
        <el-form-item label="选择供应商" required>
          <el-select
            v-model="form.supplier_id"
            filterable
            clearable
            :disabled="type === 'detail' || type === 'examine' ? true : false"
          >
            <template v-for="item in supplierList">
              <el-option
                :label="item.supplier_name"
                :value="item.supplier_id"
                :key="item.supplier_id"
              ></el-option>
            </template>
          </el-select>
        </el-form-item>
        <el-form-item label="备注信息">
          <el-input
            type="textarea"
            style="width: 300px"
            v-model="form.remark"
            :disabled="type === 'detail' || type === 'examine' ? true : false"
          ></el-input>
        </el-form-item>
        <el-form-item label="创建时间" v-if="type === 'edit' && row.status === 30">
          <el-date-picker
            v-model="form.create_time"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="选择日期时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="审核时间" v-if="type === 'edit' && row.status === 30">
          <el-date-picker
            v-model="form.audit_time"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="选择日期时间">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div>
        <el-button
          type="primary"
          style="margin-bottom: 10px"
          @click="handleAdd"
          v-if="type !== 'detail' && type !== 'examine'"
          >新增行</el-button
        >
        <div class="table">
          <el-table
            :data="tableData"
            style="width: 100%"
            stripe
            v-loading="loading"
            :row-class-name="picRowClassName"
          >
            <template v-for="item in rowList">
              <el-table-column
                :key="item.field_alias"
                :prop="item.field_alias"
                :label="item.field_text"
                align="center"
                :width="item.t_width"
              >
                <template slot="header" slot-scope="scope">
                  <span :key="item.field_alias">
                    <span v-if="item.require" style="color: #f56c6c">*</span>
                    {{ item.field_text }}
                  </span>
                </template>
                <template slot-scope="scope">
                  <template v-if="item.field_alias === 'index'">
                    {{ scope.row.index }}
                  </template>
                  <template v-if="item.field_alias === 'attr_value_id'">
                    <el-select
                      v-model="scope.row.attr_value_id"
                      filterable
                      clearable
                      :disabled="
                        type === 'detail' || type === 'examine' ? true : false
                      "
                    >
                      <template v-for="item in materialList">
                        <el-option
                          :label="item.attr_value"
                          :value="item.attr_value_id"
                          :key="item.attr_value_id"
                        ></el-option>
                      </template>
                    </el-select>
                  </template>
                  <template v-if="item.field_alias === 'gold_weight'">
                    <el-input
                      v-model="scope.row.gold_weight"
                      @input="handleInput(scope.row)"
                      :disabled="
                        type === 'detail' || type === 'examine' ? true : false
                      "
                      type="number"
                    ></el-input>
                  </template>
                  <template v-if="item.field_alias === 'unit_price'">
                    <el-input
                      v-model="scope.row.unit_price"
                      @input="handleInput(scope.row)"
                      type="number"
                      :disabled="
                        type === 'detail' || type === 'examine' ? true : false
                      "
                    ></el-input>
                  </template>
                  <template v-if="item.field_alias === 'amount_money'">
                    {{ scope.row.amount_money || "0.00" }}
                  </template>
                  <template v-if="item.field_alias === 'remark'">
                    <el-input
                      v-model="scope.row.remark"
                      :disabled="
                        type === 'detail' || type === 'examine' ? true : false
                      "
                    ></el-input>
                  </template>
                </template>
              </el-table-column>
            </template>
            <el-table-column
              label="操作"
              align="center"
              width="180px"
              v-if="type !== 'detail' && type !== 'examine'"
            >
              <template slot-scope="scope">
                <el-button
                  type="text"
                  style="color: #f56c6c"
                  @click="handleDel(scope.row.index)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <el-button
          v-if="type !== 'detail' && type !== 'examine'"
          type="primary"
          style="width: 150px; margin: 20px auto; display: block"
          @click="onSubmit"
          >保存</el-button
        >
        <div v-if="type === 'examine'" class="btns">
          <el-button type="primary" @click="handleAudit(30)"
            >审核通过</el-button
          >
          <el-button @click="handleAudit(20)">审核不通过</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import numeral from "numeral";
import { getListReq } from "@/api/system/supplier";
import {
  getMaterialListReq,
  createDataReq,
  handleAuditReq,
  getDetailReq,
  updateDateReq,
  backUpdateDateReq,
} from "@/api/goods/raw/out/index";

export default {
  data() {
    return {
      content: "",
      loading: false,
      form: {},
      tableData: [{
        remark: "",
      }],
      rowList: [
        { field_text: "序号", field_alias: "index" },
        { field_text: "材质成色", field_alias: "attr_value_id", require: true },
        { field_text: "重量", field_alias: "gold_weight", require: true, min: 0 },
        { field_text: "单价", field_alias: "unit_price", require: true, min: 0 },
        { field_text: "金额", field_alias: "amount_money", require: true },
        { field_text: "备注", field_alias: "remark" },
      ],
      supplierList: [],
      materialList: [],
    };
  },
  created() {
    this.getList();
    this.getMaterialList();
    if (this.type === 'detail' || this.type === 'examine' || this.type === 'edit') {
      this.getDetail();
    }
    if (this.type === "add") {
      this.content = "新增金料出库";
    }
    if (this.type === "detail") {
      this.content = "金料出库详情";
    }
    if (this.type === "examine") {
      this.content = "金料出库审核";
    }
    if (this.type === "edit") {
      this.content = "金料出库编辑";
    }
  },
  props: {
    row: Object,
    type: String,
  },
  methods: {
    getDetail() {
      getDetailReq({
        material_outflow_id: this.row.material_outflow_id,
      })
        .then((res) => {
          if (res.code === 1) {
            this.form = {
              material_outflow_number: res.data.material_outflow_number,
              supplier_id: res.data.supplier_id,
              remark: res.data.remark,
              create_time: res.data.create_time,
              audit_time: res.data.audit_time,
            };
            this.tableData = res.data.goods;
          }
        });
    },
    // 修改状态
    handleAudit(status) {
      let content = '';
      if (status === 30) {
        content = '确定要审核通过吗？'
      }
      if (status === 20) {
        content = '确定要审核不通过吗？'
      }
      this.$confirm(content, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          handleAuditReq({
            material_outflow_id: this.row.material_outflow_id,
            status,
          })
            .then((res) => {
              if (res.code === 1) {
                this.$message.success("操作成功");
                this.$emit('onBack', 'getList');
              }
            })
            .catch(() => {
              this.currRow.select = "";
            });
        })
        .catch(() => {});
    },
    onSubmit() {
      // 校验
      if (!this.form.supplier_id) {
        this.$message.error('请选择供应商');
        return;
      }
      if (!this.tableData.length) {
        this.$message.error("请添加金料");
        return;
      }
      for (let i = 0; i < this.tableData.length; i += 1) {
        const row = this.tableData[i];
        for (let j = 0; j < this.rowList.length; j += 1) {
          const item = this.rowList[j];
          if (item.require) {
            if (!row[item.field_alias]) {
              this.$message.error(`${item.field_text}为必填值`);
              return;
            }
          }
          if (item.min === 0) {
            if (Number(row[item.field_alias]) < item.min) {
              this.$message.error(`${item.field_text}不能小于${item.min}`);
              return;
            }
          }
        }
      }
      if (this.type === 'add') {
        createDataReq({
          ...this.form,
          arr_goods: JSON.stringify(this.tableData),
        }).then((res) => {
          if (res.code === 1) {
            this.$message.success("操作成功");
            this.$emit("onBack", "getList");
          }
        });
      }
      // 驳回编辑
      if (this.type === 'edit' && this.row.status !== 30) {
        updateDateReq({
          material_outflow_id: this.row.material_outflow_id,
          ...this.form,
          arr_goods: JSON.stringify(this.tableData),
        }).then((res) => {
          if (res.code === 1) {
            this.$message.success("操作成功");
            this.$emit("onBack", "getList");
          }
        });
      }
      // 反确认编辑
      if (this.type === 'edit' && this.row.status === 30) {
        backUpdateDateReq({
          material_outflow_id: this.row.material_outflow_id,
          ...this.form,
          arr_goods: JSON.stringify(this.tableData),
        }).then((res) => {
          if (res.code === 1) {
            this.$message.success("操作成功");
            this.$emit("onBack", "getList");
          }
        });
      }
    },
    picRowClassName({ row, rowIndex }) {
      // 给每一行添加索引
      row.index = rowIndex + 1;
    },
    handleDel(index) {
      this.tableData.splice(index - 1, 1);
    },
    handleInput(row) {
      if (row.gold_weight && row.unit_price) {
        row.amount_money = Number(row.gold_weight) * Number(row.unit_price);
        row.amount_money = numeral(row.amount_money).format("0.00");
      }
    },
    handleAdd() {
      if (this.type === 'add') {
        this.tableData.push({
          remark: "",
        });
      } else {
        this.tableData.push({
          material_outflow_id: this.row.material_outflow_id,
          material_outflow_goods_id: 0,
          remark: "",
        });
      }
    },
    goBack() {
      this.$emit("onBack");
    },
    // 获取材质成色列表
    getMaterialList() {
      getMaterialListReq().then((res) => {
        if (res.code === 1) {
          this.materialList = res.data;
        }
      });
    },
    // 获取供应商列表
    getList() {
      getListReq({
        page: 1,
        limit: 9999,
      }).then((res) => {
        if (res.code === 1) {
          this.supplierList = res.data.list;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.btns {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
// /deep/ .el-input.is-disabled .el-input__inner {
//   background: white;
// }
// /deep/ .el-textarea.is-disabled .el-textarea__inner {
//   background: white;
// }
.box {
  background: white;
  padding: 20px;
}
</style>
